import React from 'react'
import s from "./AccountCard.module.css";
import {useTranslation} from "react-i18next";
import MyCardButton from "../../../../ui/MyCardBtn/MyCardButton";
import icon from '../../../../assets/img/currency/USDT.svg'

export const AccountCardV2 = ({acc, isTest, disabled, withdrawalHandler, crypto, setShowDepositModal, setShowWithdrawalModal}) => {
  const {t} = useTranslation()
  const copyHandler = () => {
    navigator.clipboard.writeText(acc.accountNumber);
  };

  return (
      <div className={s.cardWrapper}>

        <div className={s.infoWrapper}>
          <div className={s.infoBlock}>
            <img className={s.coinIcon} src={icon}/>
            <div>
              <p className={s.currency}>USDT</p>
              <p className={s.fullCurrency}>(Tether)</p>
            </div>
            <span className={s.plate}>TRC-20</span>
          </div>


          <div className={s.amountWrapper}>
            <p className={s.amount}>Amount</p>
            <div className={s.amountBlock}>
              <p className={s.amountValue}>0</p>
              <p className={s.amountCoin}>USDT</p>
            </div>
          </div>
        </div>
        <div className={s.buttonsWrapper}
        ><MyCardButton onClick={()=>setShowDepositModal(true)} icon={'deposit'}/>
          <MyCardButton onClick={()=>setShowWithdrawalModal(true)} icon={'export'} type={'export'}/></div>
      </div>
  )
}