import React from 'react';
import s from './Statement.module.css'
import {useTranslation} from "react-i18next";
import {AuthAPI} from "../../networking/Api";



const Statement = () => {
  const {t} = useTranslation();

  const getStatement = (e) => {
    const type = e.target.textContent.toLowerCase()
    const token = localStorage.getItem('token')
    AuthAPI.getStatement(token, type).then(res => {
          const url = `https://api1.dataprotect.site/${res.data.result.fileLink}`;
          window.open(url, '_blank');
        }
    )
  }


  return (
      <div className={s.statementWrapper} onClick={(e) => getStatement(e)}>
        <p className={s.downloadTitle}>{t("personal.transactionHistory.download")}</p>
        <p className={s.buttonTitle}>PDF</p>
        <div className={s.separator}/>
        <p className={s.buttonTitle}>XLSX</p>
        <div className={s.separator}/>
        <p className={s.buttonTitle}>CSV</p>
      </div>
  )
}


export default Statement
