import React, {useEffect, useState} from 'react';
import s from './CryptoAccounts.module.css';
import {AuthAPI} from '../../../../networking/Api';
import WithdrawalConfirmModal from '../../../../ui/modals/WithdrawalConfirmModal/WithdrawalConfirmModal';
import {Layout} from 'antd';
import {AccountCardV2} from '../AccountCardV2/AccountCardV2';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from '../../../../ui/Loader/Loader';
import {getAccountsTransactions, getWithdrawalsTransactions,} from '../../../../networking/services/accounts';
import {setIsLoading} from "../../../../store/Slices/DataReducer";
import CryptoDepositModal from "../../../../ui/modals/CryptoDepositModal/CryptoDepositModal";
import CryptoWithdrawalModal from "../../../../ui/modals/CryptoWithdrawalModal/CryptoWithdrawalModal";


const CryptoAccounts = () => {
  const dispatch = useDispatch();

  const {token} = useSelector((store) => store.auth);
  const {isLoading} = useSelector((store) => store.data);

  const [accounts, setAccounts] = useState([]);
  const [isTest, setIsTest] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [disabled, setIsDisabled] = useState(false);
  const [status, setStatus] = useState(0);
  const [cryptoWallets, setCryptoWallets] = useState([])
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)

  const cryptoDate = cryptoWallets?.map((item) => {
    return {
      accountNumber: item.address,
      balance: item.netAmount,
      currency: item.coin.toUpperCase()
    }
  })

  console.log(cryptoDate)

  function withdrawalHandler(accNumber) {
    setIsDisabled(true);
    AuthAPI.createWithdrawalToBankAccount(token, accNumber)
        .then((res) => {
          setStatus(res.status);
        })
        .catch((e) => {
          setStatus(e.status);
        })
        .finally(() => {
          setIsDisabled(false);
          setShowConfirmModal(true);
        });
  }

  useEffect(() => {
    dispatch(setIsLoading(true))
    AuthAPI.getCryptoWallets(token).then((res) => {
      setCryptoWallets(res.data.result)
    }).catch((e) => {
      console.log(e)
      dispatch(setIsLoading(false))
    }).finally(dispatch(setIsLoading(false)));
  }, []);

  useEffect(() => {
    dispatch(getAccountsTransactions(token));
    dispatch(getWithdrawalsTransactions(token));
  }, []);


  return (
      <Layout className={s.layout}>
        <WithdrawalConfirmModal
            status={status}
            showConfirmModal={showConfirmModal}
            showPopUp={setShowConfirmModal}
        />
        <CryptoDepositModal
            showDepositModal={showDepositModal}
            showPopUp={setShowDepositModal}/>
        <CryptoWithdrawalModal
            showWithdrawalModal={showWithdrawalModal}
            showPopUp={setShowWithdrawalModal}/>

        {isLoading ? (
            <Loader/>
        ) : (
            <>
              <div className={s.accounts_list_wrapper}>
                <AccountCardV2 setShowDepositModal={setShowDepositModal} setShowWithdrawalModal={setShowWithdrawalModal}
                               crypto/>
                <AccountCardV2 setShowDepositModal={setShowDepositModal}/>
                <AccountCardV2 setShowDepositModal={setShowDepositModal}/>
                <AccountCardV2 setShowDepositModal={setShowDepositModal}/>
                <AccountCardV2 setShowDepositModal={setShowDepositModal}/>
                {/*{cryptoDate &&
                cryptoDate?.map((acc) => (
                <AccountCard
                    crypto
                  key={acc.accountNumber}
                  acc={acc}
                  isTest={isTest}
                  disabled={disabled}
                  withdrawalHandler={withdrawalHandler}
                />
              ))}*/}
              </div>
              {/* <AccountTransactions accounts={accounts} />*/}
            </>
        )}
      </Layout>
  );
};

export default CryptoAccounts;
