import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import s from './CryptoWithdrawalModal.module.css'
import {useTranslation} from "react-i18next";
import {CloseSvg, CopySvg, MySvg} from "../../../utils/sprite";
import MyInputForm from "../../MyInputForm/MyInputForm";
import MyBtnOrange from "../../MyBtnOrange/MyBtnOrange";



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function CryptoWithdrawalModal(props) {
  const classes = useStyles();
  const closePopup = (arg) => {
    props.showPopUp(arg)
  }

  const {t} = useTranslation()
  const currency = {
    currencyName: 'USDT TRC20',
    qr: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/MicroQR_Example.png/220px-MicroQR_Example.png',
    address: 'f4F0G01hfgjyjHtf4F0G01hfgjyjHtf4F0G01hfgjyjHt'
  }

  return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.showWithdrawalModal}
          onClose={() => {
            closePopup(false)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
      >
        <Fade in={props.showWithdrawalModal}>
          <div className={s.wrapper}>
            <CloseSvg className={s.closeIcon} onClick={()=> closePopup(false)}/>
            <div className={s.content}>
              <div>
              <p className={s.title}>Withdrawal</p>
              <p className={s.subTitle}>{currency.currencyName}</p>
              </div>
              <div className={s.inputWrapper}>
                <p className={s.inputLabel}>To wallet address</p>
                <MyInputForm style={{minWidth: 256}}  />
              </div>

              <div className={s.inputWrapper}>
                <div className={s.inputLabelWrapper}> <p className={s.inputLabel}>Amount</p>
                <p className={s.labelButton}>All</p>
                </div>
                <MyInputForm style={{minWidth: 256}} placeholder={currency.currencyName} />
              </div>

              <div className={s.lineWrapper}>
                 <p className={s.lineLabel}>Commission amount</p>
                  <p className={s.lineValue}>0.00 USDT TRC20</p>
              </div>

              <div className={s.lineWrapper}>
                <p className={s.lineLabel}>Total amount</p>
                <p className={s.totalValue}>0.00 USDT TRC20</p>
              </div>

              <div className={s.buttonWrapper}>
                <MyBtnOrange  name={'Withdraw'} />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
  );
}


export default CryptoWithdrawalModal;
