import React, {useEffect, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import LoginHeader from '../../components/Header/LoginHeader/LoginHeader';
import s from './PersonalAccount.module.css';
import {useTranslation} from 'react-i18next';
import {Button, Layout, Menu} from 'antd';
import Sider from 'antd/es/layout/Sider';
import {Content} from 'antd/es/layout/layout';
import {AccountsSvg, AcquiringTransactionsSvg, MobileMenuCloseSvg,} from '../../utils/sprite';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {setActiveMenu} from "../../store/Slices/AuthReducer";
import logo from "../../assets/img/logo.svg";

function getItem(label, key, icon, link) {
  return {
    key,
    icon,
    label,
    link,
  };
}


const desktopSiderStyle = {
  background: '#F6F4F2',
  boxShadow: 'none',
  borderRight: '1px solid #D9D9D9',
  height: '100vh',
  zIndex: 22,
  paddingTop: 32,
};

const mobileSiderStyle = {
  background: '#3F3F3F',
  boxShadow: 'none',
  paddingTop: 60,
  zIndex: 21,
  position: 'absolute',
  top: 0,
  bottom: 0,
};

const mobileMenuActiveIcon = {
  zIndex: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  width: 'fit-content',
  height: 'fit-content',
  position: 'absolute',
  top: 16,
  left: 16,
};

const mobileMenuUnactiveIcon = {
  display: 'none',
};

const menuItemStyleMobile = {
  padding: '8px 16px',
  height: '56px',
  boxSizing: 'border-box',
  margin: 0,
  width: '100%',
};

const menuItemStyleDesktop = {
  padding: '13px 0px 13px 46px',
  height: '72px',
  boxSizing: 'border-box',
  margin: 0,
  width: '100%',
};

const mobileContentStyle = {
  padding: '23px 16px',
  marginTop: 90,
  height: '100vh',
  background: 'transparent',
  width: '100%',
};

const desktopContentStyle = {
  marginTop: 83,
  minHeight: 280,
  backgroundColor: 'F6F4F2',
  overflow: 'auto',
};

const PersonalAccount = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [collapsed, setCollapsed] = useState(width <= 620);
  const {isAuth, token, activeMenu} = useSelector((store) => store.auth);

  const navMenuItems = [
    getItem(
        t("personal.acquiringTransactions"),
        '1',
        <AcquiringTransactionsSvg active={activeMenu && activeMenu[0]} width={width}/>,
        '/personal/acquiring-transactions'
    ),
    getItem(
        t("personal.yourAccounts"),
        '2',
        <AccountsSvg active={activeMenu && activeMenu[0]} width={width}/>,
        '/personal/accounts'
    ),
    /*    getItem(
          'Rolling reserve',
          '3',
          <RollingReserveSvg active={selectedNav[0]} width={width} />,
          '/personal/rolling-reserve'
        ),
        getItem(
          'networking settings',
          '4',
          <ApiSettingsSvg active={selectedNav[0]} width={width} />,
          '/personal/networking-settings'
        ),*/
  ];




  useEffect(() => {
    if (isAuth === false || token === '') {
      navigate('/');
    }
  }, [isAuth, token]);

  const handleMenuClick = ({key}) => {
    dispatch(setActiveMenu([key]))
  };

  useEffect(() => {
    return () => dispatch(setActiveMenu(['1']))
  }, [])

  const navigateHandler = (link) => {
    navigate(link);
  };

  return (
      <Layout style={{width: '100vw', height: '100vh', overflow: 'auto', backgroundColor: '#F6F4F2'}}>
        <Button
            type='text'
            icon={<MobileMenuCloseSvg/>}
            onClick={() => setCollapsed(!collapsed)}
            style={
              collapsed || width > 620
                  ? mobileMenuUnactiveIcon
                  : mobileMenuActiveIcon
            }
        />
        <LoginHeader collapsed={collapsed} setCollapsed={setCollapsed}/>
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint={'md'}
            collapsedWidth='0'
            width={width > 620 ? 316 : 180}
            style={width > 620 ? desktopSiderStyle : mobileSiderStyle}
        >
          <Menu
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
              }}
              mode='inline'
              selectedKeys={activeMenu}
              onClick={handleMenuClick}
              defaultSelectedKeys={activeMenu}
          >
            <Link to={"/"} className={s.logoWrapper}>
              <div className={s.logoBlock}>
                <img width={31} height={36} src={logo} alt=""/>
                <p className={s.logo}>
                  BingoPay
                </p>
              </div>
            </Link>
            {navMenuItems.map((item) => (
                <Menu.Item
                    onClick={() => navigateHandler(item.link)}
                    style={width > 620 ? menuItemStyleDesktop : menuItemStyleMobile}
                    className={
                      activeMenu?.includes(item.key)
                          ? `${s.menuItem} ${s.activeMenuItem}`
                          : `${s.menuItem}`
                    }
                    key={item.key}
                    icon={item.icon}
                >
                  {item.label}
                </Menu.Item>
            ))}
          </Menu>
          <div className={collapsed ? s.mobileInfo : s.infoWrapper}>
            <div className={s.bingo}>BingoPay</div>
            <div className={s.copyright}>
              <span>Copyright © 2023</span>
              <span> All rights reserved</span>
            </div>
          </div>
        </Sider>
        <Content style={width > 620 ? desktopContentStyle : mobileContentStyle}>
          <Outlet/>
        </Content>
      </Layout>
  );
};

export default PersonalAccount;
