import React, {useEffect, useState} from 'react';
import style from "./LoginHeader.module.css";
import logo from "../../../assets/img/logo.svg";
import person from "../../../assets/img/personIcon.png";
import MyBtnBlack from "../../../ui/MyBtnBlack/MyBtnBlack";
import {useNavigate} from "react-router";
import { useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LogoutConfirmModal from "../../../ui/modals/LogoutConfirmModal/LogoutConfirmModal";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {MobileMenuCloseSvg, MobileMenuSvg, UserIconSvg} from "../../../utils/sprite";
import {logout} from "../../../store/Slices/AuthReducer";

const LoginHeader = (props) => {
  const [showConfirmModal, setIsShowConfirmModal] = useState(false)

  const {firstName, lastName } = useSelector(store=>store.auth.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const Logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('isAuth')
    dispatch(logout())
    navigate('/')
  }


  return (
      <div className={style.header_wrapper}>
        <LogoutConfirmModal logout={Logout} showConfirmModal={showConfirmModal} showPopUp={setIsShowConfirmModal}/>
        <div className={`${style.header} ${style.desktop}`}>

          <div className={style.flex}>
            <div className={style.personBlock}>
              <img src={person} alt=""/>
              <div className={style.person}>{`${firstName} ${lastName}`}</div>
            </div>
            {/*<MobileFilter />*/}
            <MyBtnBlack name={t("header.signout")} onClick={() => setIsShowConfirmModal(true)}/>
          </div>
        </div>
        <div className={style.mobile}>
          <div className={style.header_buttons_wrapper}>
            <Button type="text"
                    icon={props.collapsed ? <MobileMenuSvg/> : <MobileMenuCloseSvg/> }
                    onClick={() => props.setCollapsed(!props.collapsed)}
                    style={{
                      zIndex: 24,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '16px',
                      width: "fit-content",
                      height: "fit-content",
                      position: "relative"
                    }}/>
            <div>
              <MyBtnBlack name={t("header.signout")} onClick={() => setIsShowConfirmModal(true)}/>
            </div>
          </div>
          <div className={style.logo_block}>
            <Link to={"/"} className={style.myLink}>
              <img src={logo} alt=""/>
              <div className={style.logo}>
                BingoPay
              </div>
            </Link>

          </div>
          <div className={style.personBlock}>
            <UserIconSvg size={24} />
            <div className={style.person}>{`${firstName} ${lastName}`}</div>
          </div>
        </div>
      </div>

  );
};



export default LoginHeader;
