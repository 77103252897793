import * as React from 'react';
import {useState} from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import s from './Pricing.module.css'
import classNames from "classnames";
import {useTranslation} from "react-i18next";




export default function Pricing() {
  const {t} = useTranslation()

  const data = [
    {
      key: t("pricing.table1"),
      lowRisk: t("pricing.col1"),
      mediumRisk: t("pricing.col2"),
      highRisk: t("pricing.col3"),
      highRiskPlus: t("pricing.col4")
    },
    {
      key: t("pricing.table2"),
      lowRisk: t("pricing.except"),
    },
    {
      key: t("pricing.table3"),
      lowRisk: 'AED, AFN, ALL, AMD, ANG, AOA, ARS, AUD, AWG, AZN, BAM, BBD, BDT, BGN, BIF, BMD, BND, BOB, BRL, BSD, BWP, BY, BZD, CAD, CDF, CHF, CLP, CNY, COP, CRC, CVE, CZK, DJF, DKK, DOP, DZD, EGP, ETB, FJD, FKP, GBP, GEL, GIP, GMD, GNF, GTQ, GYD, HKD, HNL, HRK, HTG, HUF, IDR, ILS, INR, ISK, JMD, JPY, KES, KGS, KHR,KMF, KRW, KYD, KZT, LAK, LBP, LKR, LRD, LSL, MAD, MDL, MGA, MKD, MMK, MNT, MOP, MRO, MUR, MVR, MWK, MXN, MYR, MZN, NAD, NGN, NIO, NOK, NPR, NZD, PAB, PEN, PGK, PHP, PKR, PLN, PYG, QAR, RON, RSD, RUB, RWF, SAR, SBD, SCR, SEK, SGD, SHP, SLE, SLL, SOS, SRD, STD, SZL, THB, TJS, TOP, TRY, TTD, TWD, TZS, UAH, UGX, UYU, UZS, VND, VUV, WST, XAF, XCD, XOF, XPF, YER, ZAR, ZMW',
    },
    {
      key:  t("pricing.table4"),
      lowRisk: '750 EUR ',
    },
    {
      key: t("pricing.table5"),
      lowRisk: '1.5%',
      mediumRisk: '2%',
      highRisk: '3.5% + 0.3 EUR',
      highRiskPlus: '4-6% + 0.3 EUR'
    },
    {
      key: t("pricing.table6"),
      lowRisk: '1.75%',
      mediumRisk: '2.5%',
      highRisk: '4% + 0.3 EUR',
      highRiskPlus: '6% + 0.3 EUR'
    },
    {
      key: t("pricing.table7"),
      lowRisk: '20,000 EUR NO RR',
      mediumRisk: '50,000 EUR NO RR',
      highRisk: '70,000 EUR NO RR',
      highRiskPlus: '100,000 EUR NO RR'
    },
    {
      key: t("pricing.table8"),
      lowRisk: 'Debit, Credit, Premium, Business, Commercial',
    },
    {
      key: t("pricing.table9"),
      lowRisk: 'Visa/MasterCard/Union Pay worldwide, Apple Pay, Discover, AmEx, JCB',
    },
    {
      key: t("pricing.table10"),
      lowRisk: '2,500 EUR',
      mediumRisk: '5,000 EUR',
      highRisk: '7,500 EUR',
      highRiskPlus: '10,000 EUR'
    },
    {
      key: t("pricing.table11"),
      lowRisk: t("pricing.no"),
      mediumRisk: t("pricing.no"),
      highRisk: t("pricing.no"),
      highRiskPlus: t("pricing.no")
    },
    {
      key: t("pricing.table12"),
      lowRisk: 'Т + 7 days',
    },
    {
      key: <div className={s.settlement}>
        <p>{t("pricing.table13")}<br/> {t("pricing.table14")}</p>
        <div className={`${s.settlementTextWrapper} ${s.hide}`}>
          <div style={{position: "relative", top: -9}}>
            <p>EUR:</p>
            <p>USD:</p>
          </div>
          <p>GBR:</p>
          <p style={{position: "relative", top: 9}}>{t("pricing.table15")}</p>
        </div>
      </div>,
      lowRisk: <div className={s.settlementTextWrapper}>
        <div>
          <p>15 EUR</p>
          <p>50 USD</p>
        </div>
        <div>
          <p>10 GBP <span>(to UK bank)</span></p>
          <p>20 GBP <span>(to non UK bank)</span></p>
        </div>
        <p>55 EUR</p>
      </div>,
      mediumRisk: <div className={s.settlementTextWrapper}>
        <div>
          <p>30 EUR</p>
          <p>100 USD</p>
        </div>
        <p>50 GBR</p>
        <p>55 EUR</p>
      </div>,
      highRisk: <div className={s.settlementTextWrapper}>
        <div>
          <p>0.3% min. 30 EUR</p>
          <p>0.5%, min. 100 USD</p>
        </div>
        <p>0.5%, min. 50 GBP</p>
        <p>55 EUR</p>
      </div>,
      highRiskPlus: <div className={s.settlementTextWrapper}>
        <div>
          <p>0.3% min. 30 EUR</p>
          <p>0.5%, min. 100 USD</p>
        </div>
        <p>0.5%, min. 50 GBP</p>
        <p>55 EUR</p>
      </div>,
    },
    {
      key: t("pricing.table25"),
      lowRisk: 'no',
      mediumRisk: 'no',
      highRisk: 'no',
      highRiskPlus: 'no'
    },
    {
      key: <div className={s.payouts}><p>{t("pricing.table16")}</p><p className={s.hide}>{t("pricing.table17")}</p></div>,
      lowRisk: <div className={s.payouts}><p>1% + 1 EUR</p><p className={s.hide}>1%</p></div>,
      mediumRisk: <div className={s.payouts}><p>—</p><p className={s.hide}>1.5%</p></div>,
      highRisk: <div className={s.payouts}><p>—</p><p className={s.hide}>2%</p></div>,
      highRiskPlus: <div className={s.payouts}><p>—</p><p className={s.hide}>2.5%</p></div>
    },
    {
      key: <div className={s.payouts}><p className={s.hide}>{t("pricing.table16")}</p><p>{t("pricing.table17")}</p></div>,
      lowRisk: <div className={s.payouts}><p className={s.hide}>1% + 1 EUR</p><p>1%</p></div>,
      mediumRisk: <div className={s.payouts}><p className={s.hide}>—</p><p>1.5%</p></div>,
      highRisk: <div className={s.payouts}><p className={s.hide}>—</p><p>2%</p></div>,
      highRiskPlus: <div className={s.payouts}><p className={s.hide}>—</p><p>2.5%</p></div>
    },
    {
      key: t("pricing.table18"),
      lowRisk: '0,35 EUR',
      mediumRisk: '1.0 EUR',
      highRisk: '2.0 EUR',
      highRiskPlus: '3.0 EUR'
    },
    {
      key: t("pricing.table19"),
      lowRisk: 'MDR%X2',
      mediumRisk: 'MDR%X2',
      highRisk: 'MDR%X2',
      highRiskPlus: 'MDR%X2'
    },
    {
      key: t("pricing.table20"),
      lowRisk: '25 EUR',
      mediumRisk: '30 EUR',
      highRisk: '50 EUR',
      highRiskPlus: '75 EUR'
    }

  ]

  const cat = ['lowRisk', 'mediumRisk', 'highRisk', 'highRiskPlus']


  const threshold = 100;

  const [selectedColumn, setSelectedColumn] = useState(0)
  const [pageXstart, setPageXstart] = useState(0)

  let view = cat[selectedColumn]

  const startTouch = (e) => {
    e.preventDefault()
    setPageXstart(0);
    const touch = e.changedTouches[0];
    setPageXstart(touch.pageX);
  }

  const endTouch = (e) => {
    e.preventDefault()
    let touch = e.changedTouches[0];
    let distX = touch.pageX - pageXstart;
    if (Math.abs(distX) >= threshold && distX < 0) {
      {
        selectedColumn < 3 && setSelectedColumn(prevState => prevState + 1)
      }
    }
    if (Math.abs(distX) >= threshold && distX > 0) {
      {
        selectedColumn > 0 && setSelectedColumn(prevState => prevState - 1)
      }
    }
  }

  const handleMenuNavigation = (direction) => {
    if(direction==='right' && selectedColumn < 3){
      setSelectedColumn(prevState => prevState + 1)
    }
    if(direction==='left' && selectedColumn > 0){
      setSelectedColumn(prevState => prevState -1)
    }
  }

  return (
      <div className={s.pageWrapper}>
        <Header/>
        <div className={s.contentWrapper}>
          <div className={s.titleWrapper}>
            <h2 className={s.title}>{t("pricing.title")}</h2>
            <div className={s.buttonsBlock}>
              <button  onClick={()=>handleMenuNavigation('left')} className={classNames(s.menuButton, {
                [s.unactive]: selectedColumn === 0,
              })}></button>
              <button onClick={()=>handleMenuNavigation('right')} className={classNames(s.menuButton, {
                [s.unactive]: selectedColumn === 3,
              })}></button>
            </div>
          </div>
          <div className={s.table}>
            <div style={{transform: `translateX(${-240 * selectedColumn}px)`}} className={s.tableHeader}>
              <div>

              </div>
              <div className={s.headerItem}>
                {t('pricing.table21')}
              </div>
              <div className={s.headerItem}>
                {t('pricing.table22')}
              </div>
              <div className={s.headerItem}>
                {t('pricing.table23')}
              </div>
              <div className={s.headerItem}>
                {t('pricing.table24')}
              </div>
            </div>
            <div className={s.tableBodyWrapper}>
              {
                data.map((item, index) => {
                  return (
                      <div className={classNames(s.row, {[s.bottomBorderSider]: index === data.length - 1,
                        [s.desktopHide]: index === 15
                      })}>
                        <div className={classNames(s.siderItem, {
                          [s.bottomBorderSider]: index === data.length - 1,

                        })
                        }>{item.key}</div>
                        <div className={classNames(s.rowItem, {
                          [s.topLeftBorder]: index === 0,
                          [s.bottomLeftBorder]: index === data.length - 1,
                          [s.oneItem]: Object.keys(item).length === 2,
                          [s.shadow]: true,
                        })}>{item.lowRisk}
                        </div>
                        {item.mediumRisk && <div className={classNames(`${s.rowItem} ${s.border}`)}>{item.mediumRisk}
                        </div>}
                        {item.highRisk && <div className={`${s.rowItem} ${s.border}`}>{item.highRisk}
                        </div>}
                        {item.highRiskPlus && <div
                            className={classNames(s.rowItem, {
                              [s.topRightBorder]: index === 0,
                              [s.bottomRightBorder]: index === data.length - 1,
                              [s.border]: true
                            })}>{item.highRiskPlus}
                        </div>}
                      </div>)
                })
              }
            </div>


            <div className={s.mobileTableBodyWrapper}
                 onTouchEnd={(e) => endTouch(e)
                 }
                 onTouchStart={(e) => startTouch(e)}
            >
              {
                data.map((item, index) => {
                  return (
                      <div className={s.mobileTableItem}>
                        <div className={s.mobileItemTitle}>
                          {item.key}
                        </div>
                        <div className={s.mobileItemSubtitle}>
                          {item[view] ? item[view] : item['lowRisk']}
                        </div>
                      </div>
                  )
                })
              }
            </div>


          </div>

        </div>
        <Footer/>
      </div>
  )
}
;