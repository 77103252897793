import {createSelector} from '@reduxjs/toolkit';


export const selectAcquiringTransactions = state => state.data.acquiringTransactionsList;
export const selectActiveFilter = state => state.data.activeFilter;
export const selectSort = state => state.data.activeSort;
export const searchString = state => state.data.search
export const selectedAcquiringTransactions = createSelector(
    [selectAcquiringTransactions, selectActiveFilter, selectSort, searchString],
    (allTransactions, activeFilter, selectSort, searchString) => {

      let newArr = [...allTransactions]

      if (activeFilter !== 'All') {
        newArr = allTransactions?.filter(t => t.transaction_status === activeFilter)
      }

      if (selectSort?.order === 'descend') {
        newArr?.sort((a, b) => {
              if (selectSort.columnKey === 'transaction_date_time') {
                return Date.parse(b[selectSort.columnKey]) - Date.parse(a[selectSort.columnKey])
              } else {
                return b[selectSort.columnKey] - a[selectSort.columnKey]
              }
            }
        )
      }

      if (selectSort?.order === 'ascend') {
        newArr?.sort((a, b) => {
          if (selectSort.columnKey === 'transaction_date_time') {
            return Date.parse(a[selectSort.columnKey]) - Date.parse(b[selectSort.columnKey])
          } else {
            return a[selectSort.columnKey] - b[selectSort.columnKey]
          }
        })
      }

      if(searchString?.length > 0) {
        newArr = newArr.filter(item=>item.transaction_number.includes(searchString))
      }

      return newArr;
    }
)

export const selectAccountTransactions = state => state.accounts.accountTransactions;
export const selectWithdrawals = state => state.accounts.withdrawalTransactions;
export const accountSort = state => state.accounts.activeSort;


export const sortedAccountsTransactions = createSelector([selectAccountTransactions, accountSort],
    (accountTransactions, activeSort) => {
      if (Object.keys(activeSort).length === 0) {
        return accountTransactions;
      } else if (activeSort.order === 'descend') {
        const sortedArr = [...accountTransactions]?.sort((a, b) => {
              if (activeSort.field === 'date') {
                return Date.parse(new Date(b[activeSort.field]).toISOString()) - Date.parse(new Date(a[activeSort.field]).toISOString())
              } else {
                return b[activeSort.field] - a[activeSort.field]
              }
            }
        )
        return sortedArr;
      } else if (activeSort.order === 'ascend') {
        const sortedArr = [...accountTransactions]?.sort((a, b) => {
          if (activeSort.field === 'date') {
            return Date.parse(new Date(a[activeSort.field]).toISOString()) - Date.parse(new Date(b[activeSort.field]).toISOString())
          } else {
            return a[activeSort.field] - b[activeSort.field]
          }
        })
        return sortedArr;
      }
    }
)

export const sortedWithdrawalsTransactions = createSelector([selectWithdrawals, accountSort],
    (withdrawalsTransactions, activeSort) => {
      if (Object.keys(activeSort).length === 0) {
        return withdrawalsTransactions;
      }

      if (activeSort.order === 'descend') {
        const sortedArr = [...withdrawalsTransactions]?.sort((a, b) => {
              if (activeSort.field === 'date') {
                console.log(new Date(b[activeSort.field]).getTime())
                return new Date(b[activeSort.field]).getTime() - new Date(a[activeSort.field]).getTime()
              } else {
                return b[activeSort.field] - a[activeSort.field]
              }
            }
        )
        return sortedArr;
      }

      if (activeSort.order === 'ascend') {
        const sortedArr = [...withdrawalsTransactions]?.sort((a, b) => {
          if (activeSort.field === 'date') {
            return new Date(a[activeSort.field]).getTime() - new Date(b[activeSort.field]).getTime()
          } else {
            return a[activeSort.field] - b[activeSort.field]
          }
        })
        return sortedArr;
      }
    }
)