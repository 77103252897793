import React from 'react';
import s from './Why.module.css'
import {useTranslation} from "react-i18next";


const Why = (props) => {
    const {t} = useTranslation()
    const locale = localStorage.getItem('lang')

    return (
        <div id={"2"} className={s.container}>
            <div className={s.descBlock}>
                <div className={s.title}>{t('why.title')}</div>
                <div className={s.subtitle}>{t('why.subtitle')}</div>
            </div>
            <div className={s.cardBlock}>
                <div className={`${s.card} ${locale=== "Ru" && s.ruCard}` }>
                    <div className={s.cardTitle}>{t('why.cardTitleOne')}</div>
                    <div className={`${s.cardSubtitle} ${locale === 'Ru' || locale === 'Fr' && s.ruSubtitle}`}>{t('why.cardOneSubtitle')}</div>
                    <div className={s.cardTextBlockWrapper} >
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardOneTextOne')}</div>
                        </div>
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardOneTextTwo')}</div>
                        </div>
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardOneTextThree')}</div>
                        </div>
                    </div>
                </div>
                <div className={`${s.card} ${locale=== "Ru" && s.ruCard}` }>
                    <div className={s.cardTitle}>{t('why.cardTitleTwo')}</div>
                    <div className={`${s.cardSubtitle} ${locale === 'Ru' || locale === 'Fr' && s.ruSubtitle}`} >{t('why.cardTwoSubtitle')}
                    </div>
                    <div className={s.cardTextBlockWrapper} >
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardTwoTextOne')}</div>
                        </div>
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardTwoTextTwo')}</div>
                        </div>
                        <div className={s.cardTextBlock}>
                            <div className={s.line}></div>
                            <div className={s.cardText}>{t('why.cardTwoTextThree')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Why;
