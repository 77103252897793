import {combineReducers, configureStore, createStore} from '@reduxjs/toolkit';

import DataReducer from './Slices/DataReducer';
import thunkMiddleware from 'redux-thunk';
import AuthReducer from "./Slices/AuthReducer";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccountsReducer from "./Slices/AccountsReducer";


const persistConfig = {
  key: "root",
  storage,
};


const rootReducer = combineReducers({
  auth: AuthReducer,
  data: DataReducer,
  accounts: AccountsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
});

export const persistor = persistStore(store);

