import React from "react";
import Nav from "../Nav/Nav";
import logo from "../../assets/img/logo.svg"
import style from "./Header.module.css"
import MyBtnOrange from "../../ui/MyBtnOrange/MyBtnOrange";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import LanguagePicker from "../../ui/LanguagePicker/LanguagePicker";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import person from "../../assets/img/personIcon.png";


function Header() {

  const {isAuth} = useSelector(state => state.auth)

  const navigate = useNavigate();
  const {t} = useTranslation();
  const redirect = () => {
    navigate('/login')
  }


  return (
      <div className={style.header}>

        <div className={style.logo_block}>
          <img src={logo} alt=""/>
          <div onClick={() => {
            navigate('/')
          }} className={style.logo}>
            BingoPay
          </div>
          <div className={style.nav}>
            <Nav/>
          </div>
        </div>
        <div className={style.flex}>

          <LanguagePicker/>
          <div className={style.btnWrapper}>
            {
              isAuth
                  ? <NavLink to={"/personal/acquiring-transactions"}>
                    <img src={person} alt=""/>
                  </NavLink>
                  : <MyBtnOrange name={t("header.signin")} onClick={() => {
                    redirect()
                  }}/>
            }
          </div>
        </div>
      </div>
  );
}

export default Header;
