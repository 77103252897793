import React, {useEffect, useState} from 'react';
import s from './AcquiringTransactions.module.css'
import MyBtnUnactive from "../../../ui/MyBtnUnactive/MyBtnUnactive";
import {AuthAPI} from "../../../networking/Api";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Statement from "../../../ui/Statement/Statement";
import {ConfigProvider, Layout, Pagination, Table} from "antd";
import TableCard from "../../../ui/TableCard/TableCard";
import {getUserDataTC, logout} from "../../../store/Slices/AuthReducer";
import i18next from "i18next";
import {
  getAcquiringTransactionData,
  setFilter,
  setIsLoading,
  setSearch,
  setSort
} from "../../../store/Slices/DataReducer";
import {transactionStatus} from "../../../utils/tableUtils";
import {selectedAcquiringTransactions} from "../../../store/selectors";
import {Loader} from "../../../ui/Loader/Loader";
import MobileFilter from "../../../ui/MobileFilter/MobileFilter";
import MyInputForm from "../../../ui/MyInputForm/MyInputForm";


const AcquiringTransactions = (props) => {
  const {t} = useTranslation()

  let btns = [
    {name: t("personal.transactionHistory.all"), key: 'All'},
    {name: t("personal.transactionHistory.inProcess"), key: 1,},
    {name: t("personal.transactionHistory.approved"), key: 2},
    {name: t("personal.transactionHistory.denied"), key: 3},
    {name: t("personal.transactionHistory.refund"), key: 4},
    {name: t("personal.transactionHistory.waiting"), key: 5},
  ]

  const width = window.innerWidth
  const pageSize = 10;
  const currentLang = localStorage.getItem('lang')
  const dispatch = useDispatch()


  const {token} = useSelector(store => store.auth)
  const {isLoading, activeFilter, search} = useSelector(store => store.data)
  const transactions = useSelector(selectedAcquiringTransactions)

  const [displayedBtns, setDisplayedBtns] = useState(btns)
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  const columns = [
    {
      title: `${i18next.t('personal.transactionHistory.transactionNumber')}`,
      dataIndex: 'id',
    },
    {
      title: `${i18next.t('personal.transactionHistory.time')}`,
      dataIndex: 'time',
    },
    {
      title: `${i18next.t('personal.transactionHistory.date')}`,
      dataIndex: 'date',
      sorter: true,
      key: 'transaction_date_time',
    },
    {
      title: `${i18next.t('personal.transactionHistory.cardNumber')}`,
      dataIndex: 'cardNumber',
    },
    {
      title: `${i18next.t('personal.transactionHistory.amount')}`,
      dataIndex: 'amount',
      sorter: true,
      key: 'transaction_amount',
    },
    {
      title: `${i18next.t('personal.transactionHistory.type')}`,
      dataIndex: 'type',
    },
    {
      title: `${i18next.t('personal.transactionHistory.status')}`,
      dataIndex: 'status',
    },
    {
      title: '',
      dataIndex: 'action',
    },
  ];

  useEffect(() => {
    setDisplayedBtns(btns)
  }, [currentLang])

  useEffect(() => {
    dispatch(getAcquiringTransactionData({token})).then((res) => {
      if (res.payload.status === 418) {
        dispatch(logout())
      }
    })
    if (!!token) {
      dispatch(getUserDataTC(token))
    }
  }, [])

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data?.slice(startIndex, endIndex);
    setCurrentData(paginatedData);
  }, [currentPage, activeFilter, transactions]);


  const onChange = (pagination, filters, sorter) => {
    const {order, columnKey} = sorter
    if (order === undefined) {
      dispatch(setSort({}))
    } else {
      dispatch(setSort({columnKey, order}))
    }
  };


  const data = transactions?.map((item) => {
    return {
      key: item.transaction_number,
      id: item.transaction_number,
      time: item.transaction_date_time.substr(11, 9),
      date: item.transaction_date_time.substr(0, 11),
      cardNumber: "• • • • " + item.card_number,
      amount: `${item.transaction_amount} ${item.currency}`,
      type: `${item.is_live_transaction ? 'Live' : 'Test'}`,
      status: `${transactionStatus(item.transaction_status)}`,
      action: <div onClick={() => refundHandler(item)}
                   className={`${item.transaction_status === 2 ? s.refund : `${s.refund} ${s.disabled}`}`}>
        <img src={require('../../../assets/img/arrow_refund.png')}/>
      </div>
    }
  })


  const refundHandler = (item) => {
    if (item.transaction_status === 2) {
      dispatch(setIsLoading(true))
      AuthAPI.refund(token, item.transaction_number)
          .then((res) => {
            console.log(res)
          }).catch((e) => {
        console.log(e.error)
      }).finally(() => {
        dispatch(setIsLoading(false))
        dispatch(getAcquiringTransactionData({token}))
      })
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const customItemRender = (current, type, originalElement) => {
    if (type === 'page' && current === currentPage) {
      return <p style={{
        backgroundColor: 'transparent',
        color: '#000',
        fontSize: 18,
        fontWeight: 500,
        fontFamily: 'Cera Pro'
      }}>{current}</p>;
    }
    return originalElement;
  };


  const customPagination = {
    className: `${s.custom_pagination}`,
    current: currentPage,
    onChange: handlePageChange,
    itemRender: customItemRender,
  };


  const handleSearch = (e) => {
    if (e.target.value.trim().length > 0) {
      dispatch(setSearch(e.target.value.trim()))
    } else {
      dispatch(setSearch(null))
    }
  }

  return (
      <div className={s.tableWrapper}>
        <img className={s.blankHeader} src={require('../../../assets/img/blankHeader.png')} alt=""/>
        <div className={s.transactionHistoryDesktop}>
          {!isLoading &&
              <div className={s.topMenuWrapper}>
                <div className={s.btnBlock}>
                  {displayedBtns.map((btn) => <MyBtnUnactive key={btn.key}
                                                             className={activeFilter === btn.key ? s.active : s.unactive}
                                                             onClick={() => dispatch(setFilter(btn.key))}
                                                             name={btn.name}/>)}

                </div>
                <div className={s.secondLineWrapper}>
                  <MyInputForm
                      placeholder={'Filter by transaction number'}
                      onChange={(e) => {
                        handleSearch(e)
                      }} style={{minHeight: 43}}/>
                  <div className={s.line}>
                  <Statement/>
                  <div className={s.filterMenuWrapper}>
                    <MobileFilter/>
                  </div>
                  </div>
                </div>
            {/*    <div className={s.filterMenuWrapper}>
                  <MobileFilter/>
                </div>*/}
              </div>
          }

          <Layout style={{marginTop: 20, minHeight: '100%'}}>

            {isLoading ? <Loader/> :
                <>
                  {width > 620 ?
                      <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: '#DF853B',
                            },
                          }}
                      >
                        <Table rowClassName={s.tableRow}
                               style={{backgroundColor: '#F6F4F2'}}
                               columns={columns}
                               dataSource={data}
                               onChange={onChange}
                               pagination={customPagination}/>
                      </ConfigProvider>
                      :
                      <Layout style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        gap: 8,
                        backgroundColor: '#F6F4F2'
                      }}>
                        {currentData.map((item) =>
                            <TableCard item={item} refundHandler key={item.key}/>
                        )
                        }
                        <Pagination
                            pageSize={10}
                            total={data.length}
                            {...customPagination}
                        />
                      </Layout>
                  }</>}
          </Layout>
        </div>
      </div>
  );
};

export default AcquiringTransactions;
