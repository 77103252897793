import React from 'react'
import s from "./AccountCard.module.css";
import MyBtnUnactive from "../../../../ui/MyBtnUnactive/MyBtnUnactive";
import MyBtnOrange from "../../../../ui/MyBtnOrange/MyBtnOrange";
import {useTranslation} from "react-i18next";
import icon from "../../../../assets/img/copy.svg"

export const AccountCard = ({acc, isTest, disabled, withdrawalHandler, crypto}) => {
  const {t} = useTranslation()
  const copyHandler = () => {
    navigator.clipboard.writeText(acc.accountNumber);
  };

  return (
            <div
            key={acc.accountNumber}
            className={isTest ? `${s.accountCardWrapper} ${s.testAcc}` : s.accountCardWrapper}>
          <div className={s.infoWrapper}>
            <div className={s.titleWrapper}>
              <div className={s.title}>
                {crypto ? 'Wallet Address' : t("personal.accountList.accountNumber")}
              </div>
              <div className={s.acc_number} onClick={() => copyHandler()}>
                {acc.accountNumber} {crypto && <img className={s.copyIcon} src={icon}/>}
              </div>
            </div>
            <div className={s.total_wrap}>
              <div>
                <div className={s.title}>
                  {t("personal.accountList.netAmount")}
                </div>
                <div className={s.credits}>
                  {acc.balance}
                </div>
              </div>
              <div>
                <div className={s.title}>
                  {crypto ? 'Coin' : t("personal.accountList.currency")}
                </div>
                <div className={s.acc_number} style={{color: '#3F3F3F'}}>
                  {acc.currency}
                </div>
              </div>
            </div>
          </div>
          <div className={s.withdraw_btn_block}>
            {(isTest || acc.balance === 0) ?
                <MyBtnUnactive style={{width: 256}} name={t("personal.accountList.withdrawal")} padding
                               className={s.unactive} disabled={disabled}/> :
                <MyBtnOrange secondary style={{width: 256}} name={t("personal.accountList.withdrawal")}
                             disabled={disabled} onClick={() => {
                  withdrawalHandler(acc.accountNumber)
                }}/>}
          </div>
        </div>
  )
}