import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthAPI} from "../Api";
import {setIsLoading} from "../../store/Slices/DataReducer";
import {
  setAccountTransactions,
  setIsDetailsPopupShow, setTransactionDetails,
  setWithdrawalTransactions
} from "../../store/Slices/AccountsReducer";


export const getAccountsTransactions = createAsyncThunk(
    'accounts/getAccountTransactions',
    async (token, {dispatch}) => {
      try {
        dispatch(setIsLoading(true))
        const response = await AuthAPI.getUserWalletTransactionList(token);
        dispatch(setAccountTransactions(response.data.result));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsLoading(false))
      }
    }
);

export const getWithdrawalsTransactions = createAsyncThunk(
    'accounts/getWithdrawalsTransactions',
    async (token, {dispatch}) => {
      try {
        dispatch(setIsLoading(true))
        const response = await AuthAPI.getUserWalletWithdrawTransactionList(token);
        dispatch(setWithdrawalTransactions(response.data.result));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsLoading(false))
      }
    }
);

export const getTransactionDetails = createAsyncThunk(
    'accounts/getTransactionDetails',
    async ({transactionNumber, token}, {dispatch}) => {
      try {
        const response = await AuthAPI.getTransactionDetails(transactionNumber, token);
        dispatch(setTransactionDetails(response.data.result))
        dispatch(setIsDetailsPopupShow(true))
      } catch (error) {
        console.error(error);
      } finally {
      }
    }
);