import React, { useEffect, useState } from 'react';
import style from './AccountTransactions.module.css';
import './styles.css';
import MyBtnUnactive from '../../../../ui/MyBtnUnactive/MyBtnUnactive';
import AccountTransactionsTable from './AccountTransactionsTable/AccountTransactionsTable';
import TransactionDetailsModal from '../../../../ui/modals/TransactionDetailModal/TransactionDetailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import {sortedAccountsTransactions, sortedWithdrawalsTransactions} from "../../../../store/selectors";
import CustomSelect from "../../../../ui/CustomSelect/CustomSelect";
import {setActiveSort} from "../../../../store/Slices/AccountsReducer";

const AccountTransactions = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useSelector((store) => store.auth);
  const { isDetailsPopupShow  } =
    useSelector((store) => store.accounts);
  const accountTransactions = useSelector(sortedAccountsTransactions)
  const withdrawalTransactions =useSelector(sortedWithdrawalsTransactions)

  const btns = [
    {
      name: t('personal.accountList.accountTransactions'),
      key: 'PersonalAccountTransactions',
    },
    {
      name: t('personal.accountList.listOfWithdrawals'),
      key: 'ListOfWithdrawals',
    },
  ];

  useEffect(() => {
    setDisplayedBtns(btns);
  }, [t]);

  const [displayedBtns, setDisplayedBtns] = useState(btns);
  const [countryList, setCountryList] = useState([]);
  const [displayedData, setDisplayedData] = useState(
    'PersonalAccountTransactions'
  );

  useEffect(() => {
    setCountryList(props.accounts);
  }, [props.accounts]);

  const changeActiveBtnOnClick = (key) => {
    setDisplayedData(key);
  };

  const getDisplayedData = () => {

    if (displayedData === 'PersonalAccountTransactions') {
      return (
        <AccountTransactionsTable
          isTransactions={true}
          tableData={accountTransactions}
        />
      );
    }

    if (displayedData === 'ListOfWithdrawals') {
      return <AccountTransactionsTable tableData={withdrawalTransactions} />;
    }

  };

  return (
    <Layout >
      <TransactionDetailsModal isDetailsPopupShow={isDetailsPopupShow} />
      <div className={style.content}>
        <div className={style.title}>
          {t('personal.accountList.transactions')}
        </div>
           {/*<div className={style.select_block}>
            <div className={style.select_title}>{t("personal.accountList.accountNumber")}</div>
               <CustomSelect
                      defaultSelectText={t("personal.accountList.select")}
                      optionsList={countryList}
              />
          </div>*/}

        <div className={style.tables_nav_wrapper}>
          {displayedBtns.map((btn) => (
            <MyBtnUnactive
              key={btn.key}
              className={
                btn.key === displayedData ? style.active : style.unactive
              }
              onClick={() => changeActiveBtnOnClick(btn.key)}
              name={btn.name}
            />
          ))}
        </div>
        <div className={style.table_wrapper}>
          <div className={style.statementWrapper}></div>
          {getDisplayedData()}
        </div>
      </div>
    </Layout>
  );
};

export default AccountTransactions;
