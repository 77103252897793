import axios from 'axios';

const token = localStorage.getItem('token')

const settings = {
    headers : {'Content-type' : 'application/json'},
}

const settingsWithToken = {
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-type' : 'application/json'
    }
}

const instanceSender = axios.create({
    baseURL: "https://assembly.tehnik.tech/",
    ...settings
})

const instance = axios.create({
    baseURL : 'https://api1.dataprotect.site/',
    ...settings
})

export const AuthAPI = {
    test() {
        return instance.post(`/api/user/test`, {}, settingsWithToken)
    },
    login(data) {
        return instance.post(`/api/user/login`, data, settings)
    },
    getUserData(token) {
        return instance.get(`/api/user/data`,{headers : {Authorization: `Bearer ${token}`}})
    },
    getTransactions(token) {
        return instance.get(`/api/user/transactions`,{headers : {Authorization: `Bearer ${token}`}})
    },
    getAccountsList(token) {
        return instance.post(`/api/transaction/get/accounts/balance`, {}, {headers : {Authorization: `Bearer ${token}`}})
    },
    sendSupportMessage(data) {
        return instanceSender.post(`api/send_form/from`, data)
    },
    getUserWalletTransactionList(token) {
        return instance.get(`/api/v2/user/wallet/transaction/list`, {headers : {Authorization: `Bearer ${token}`}})
    },
    getTransactionDetails(transactionNumber,token) {
        return instance.post(`/api/v2/user/wallet/transaction/info`,{"transactionNumber": transactionNumber}, {headers : {Authorization: `Bearer ${token}`}})
    },
    createWithdrawalToBankAccount(token,account){
        return instance.post(`/api/v2/user/wallet/create/withdraw`,{"account": account}, {headers : {Authorization: `Bearer ${token}`}})
    },
    getUserWalletWithdrawTransactionList(token) {
        return instance.post(`/api/v2/user/withdraw/transaction/list`,{}, {headers : {Authorization: `Bearer ${token}`}})
    },
    refund(token, transactionId) {
        return instance.post(`/api/user/transaction/refund`,{ "transaction_id": transactionId }, {headers : {Authorization: `Bearer ${token}`}})
    },
    getStatement(token, type) {
        return instance.get(`api/user/transactions/download?file=${type}`, {headers : {Authorization: `Bearer ${token}`}})
    },
    getCryptoWallets(token) {
        return instance.get(`api/crypto/wallets`, {headers : {Authorization: `Bearer ${token}`}})
    }
}


