import React, {useEffect, useState} from 'react';
import s from './RequestForm.module.css'
import MyInputForm from "../../../ui/MyInputForm/MyInputForm";
import {AuthAPI} from "../../../networking/Api";
import {Controller, useForm} from 'react-hook-form';
import PhoneCodesInput from "../../../ui/PhoneCodesInput/PhoneCodesInput";
import MyBtnOrange from "../../../ui/MyBtnOrange/MyBtnOrange";
import {useTranslation} from "react-i18next";


const RequestForm = () => {
    const {t} = useTranslation()
    const [formSuccess, setFormSuccess] = useState({isShow: false, message: '', status: ''})
    const [code, setCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    })
    const [tempArr, setTempArr] = useState({
        "Company name": "",
        "Website": "",
        "E-mail": "",
        "Mobile Phone": "",
        "Message": "",
        "Country": " ",
    })


    const dataForSend = {
        project: "bingopay",
        subject: "Hello!",
        data: tempArr
    }


    const {control, handleSubmit , reset, formState: {errors }} = useForm({
        defaultValues: {
            companyName: '',
            website: '',
            email: '',
            mobilePhone: '',
            message: ''
        }
    });

    const onSubmit = async (data) => {

        setTempArr({
            "Company name": data.companyName,
            "Website": data.website,
            "E-mail": data.email,
            "Mobile Phone": `+${code.ph} ${data.mobilePhone}`,
            "Message": data.message,
            "Country": code.na,
        })


    }


    useEffect(() => {

        if (tempArr["Mobile Phone"]?.length > 0) {
            sendSupportMessage()
            reset()
        }
    }, [tempArr]);


    const sendSupportMessage = () => {
        setFormSuccess({
            isShow: false, message: '', status: ''
        })

        AuthAPI.sendSupportMessage(dataForSend)
            .then((res) => {

                setFormSuccess({
                    isShow: true, message:`${t('request.successMessage')}`, status: 'ok'
                })
                console.log(res)
                setTempArr({
                    "Company name": "",
                    "Website": "",
                    "E-mail": "",
                    "Mobile Phone": "",
                    "Message": "",
                    "Country": " ",
                })
                reset()
            }).catch((err) => {
            console.log(err)
            setFormSuccess({
                isShow: true, message: `${t('request.errorMessage')}`, status: ''
            })
        })
    }

    return (
        <div id={'4'} className={s.container}>
            <div className={s.title}>{t('request.title')}</div>
            <form className={s.formWrapper} onSubmit={handleSubmit(onSubmit)}>
                <label className={s.company}>
                    <p className={s.labelTitle}>{t('request.company')}<span style={{color: '#DF853B'}}> *</span></p>
                    <Controller
                        defaultValue=""
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 150,
                        }}
                        render={({field: {onChange, value}}) => (
                            <MyInputForm
                                error={errors?.companyName}
                                placeholder={t('request.companyName')}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="companyName"
                    />
                    {errors?.companyName?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                    {errors?.companyName?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
                </label>

                <label className={s.website}>
                    <p className={s.labelTitle}>{t('request.website')}<span style={{color: '#DF853B'}}> *</span></p>
                    <Controller
                        defaultValue=""
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 150,
                        }}
                        render={({field: {onChange, value}}) => (
                            <MyInputForm
                                error={errors?.website}
                                placeholder={t('request.companyWebsite')}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="website"
                    />
                    {errors?.website?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                    {errors?.website?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
                </label>

                <label className={s.email}>
                    <p className={s.labelTitle}>{t('request.email')}<span style={{color: '#DF853B'}}> *</span></p>
                    <Controller
                        defaultValue=""
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 40,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                            }
                        }}
                        render={({field: {onChange, value}}) => (
                            <MyInputForm
                                error={errors?.email}
                                placeholder={t('request.companyEmail')}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="email"
                    />
                    {errors?.email?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                    {errors?.email?.type === 'pattern' && <p className={s.error}>{t('request.mailPatternError')}</p>}
                </label>

                <label className={s.mobilePhone}>
                    <p className={s.labelTitle}>{t('request.mobile')}<span style={{color: '#DF853B'}}> *</span></p>
                    <Controller
                        defaultValue=""
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 15,
                            minLength: 5,
                        }}
                        render={({field: {onChange, value}}) => (
                            <PhoneCodesInput
                                error={errors?.mobilePhone}
                                setCode={setCode}
                                value={value.replace(/[^0-9]/g, "")}
                                onChange={onChange}
                            />
                        )}
                        name="mobilePhone"
                    />
                    {errors?.mobilePhone?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                    {errors?.mobilePhone?.type === 'pattern' && <p className={s.error}>{t('request.onlyNumbersError')}</p>}
                    {errors?.mobilePhone?.type === 'minLength' && <p className={s.error}>min 5 characters</p>}
                    {errors?.mobilePhone?.type === 'maxLength' && <p className={s.error}>max 15 characters</p>}
                </label>


                <label className={s.message}>
                    <p className={s.labelTitle}>{t('request.message')}</p>
                    <Controller
                        defaultValue=""
                        control={control}
                        rules={{
                            maxLength: 100,
                        }}
                        render={({field: {onChange, value}}) => (
                            <textarea onChange={onChange} value={value} placeholder={t('request.yourMessage')} name='message'/>)}
                        name="message"
                    />


                </label>


                <div className={s.submitButton}>
                    <MyBtnOrange name={t('request.button')}/>
                    {formSuccess.isShow &&
                        <p className={formSuccess.status === 'ok' ? s.successMessage : `${s.successMessage} ${s.fail}`}>{formSuccess.message}</p>}
                </div>

            </form>
        </div>

    );
};


export default RequestForm;
