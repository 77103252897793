import React from "react";
import mainImg from "../../../assets/img/mainImg.png"
import s from "./Main.module.css"
import style from "../../../components/Nav/Nav.module.css";
import {Link} from "react-scroll";
import MyBtnOrange from "../../../ui/MyBtnOrange/MyBtnOrange";
import {useNavigate} from "react-router";

import {useTranslation, withTranslation} from 'react-i18next';


const Main = (props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();


  return (
      <div id={"1"} className={s.mainContainer}>
        <div className={s.descBlock}>
          <div className={s.title}><span className={s.bold}>{t('main.titleOne')}</span> {t('main.titleTwo')}
            <span
                className={s.bold}> {t('main.titleThree')}</span></div>
          <div className={s.subtitleBlock}>
            <div className={s.line}></div>
            <div className={s.subtitle}>
              {t('main.subtitle')}
            </div>
          </div>
          <div>
            <Link
                activeClass={style.active}
                to="4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
              <MyBtnOrange name={`${t('main.button')}`}/></Link>
            <div className={s.buttonWrapper}>
              <MyBtnOrange name={t("header.signin")} outline onClick={() => {
              navigate('/login')
            }}/></div>


          </div>
        </div>
        <div className={s.imgBlock}>
          <img src={mainImg} alt=""/>
        </div>
      </div>
  );
};

export default withTranslation()(Main);
