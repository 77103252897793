import App from "../App";
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "../pages/Login/Login";
import ApiGuide from "../pages/ApiGuide/ApiGuide";
import Accounts from "../pages/PersonalAccount/AccountList/Accounts";
import PersonalAccount from "../pages/PersonalAccount/PersonalAccount";
import AcquiringTransactions from "../pages/PersonalAccount/AcquiringTransactions/AcquiringTransactions";
import Pricing from "../pages/Pricing/Pricing";


export const AppContainer = () => {

  return (
      <Routes>
        <Route path='/' element={<App/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/pricing' element={<Pricing/>}/>
        <Route path='/personal' element={<PersonalAccount/>}>
          <Route path='acquiring-transactions' element={<AcquiringTransactions/>}/>
          <Route path='accounts' element={<Accounts/>} />
          {/*<Route path='api-settings' element={<ApiSettings />}/>*/}
          {/*<Route path='rolling-reserve' element={<RollingReserve/>}/>*/}
          <Route path="*" element={<Navigate to="acquiring-transactions" replace />} />
        </Route>
        <Route path='/api' element={<ApiGuide/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
  )

}


