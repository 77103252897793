import React, {useEffect, useState} from 'react';
import s from './Login.module.css'
import logo from '../../assets/img/logo.svg'
import logoMobile from '../../assets/img/logo.svg'
import MyInputForm from "../../ui/MyInputForm/MyInputForm";
import MyBtnBlack from "../../ui/MyBtnBlack/MyBtnBlack";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {authMeTC} from "../../store/Slices/AuthReducer";
import {useNavigate} from "react-router";

const Login = () => {
  const {isAuth, error, token} = useSelector(store => store.auth)
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useNavigate();


  const [data, setData] = useState({
    login: '',
    password: ''
  })

  const onSubmit = () => {
    dispatch(authMeTC(data))
    setData({login: '', password: ''})
  }

  useEffect(() => {
    if (isAuth) {
      return navigate(`/personal/acquiring-transactions`)
    }
  }, [navigate, isAuth])


  return (
      <div className={s.loginContainer}>
        <div className={s.leftSide}>
          <div className={s.header}>
            <div className={s.headerBlock} onClick={() => {
              navigate('/')
            }}>
              <div className={s.logoDesktop}><img src={logo} alt=""/></div>
              <div className={s.logoMobile}><img src={logoMobile} alt=""/></div>
              <div className={s.logoTitle}>BingoPay</div>
            </div>
            <div className={s.backMobile} onClick={() => {
              navigate('/')
            }}>
              Back
            </div>
          </div>
          <div className={s.loginBlock}>
            <div className={s.loginTitle}>{t("signin.title")}</div>
            <div className={s.formBlock}>
              <div className={s.formTitle}>{t("signin.email")} *</div>
              <div><MyInputForm
                  onChange={e => setData({...data, login: e.target.value})}
                  placeholder={''}/></div>
            </div>
            <div className={s.formBlock}>
              <div className={s.formTitle}>{t("signin.password")} *</div>
              <div>
                <MyInputForm
                    onChange={e => setData({...data, password: e.target.value})}
                    placeholder={''} type="password"/></div>
            </div>
            <div className={s.error}>{error}</div>
            <div>
              <MyBtnBlack onClick={onSubmit} style={{padding: '15px 25px'}} name={t("signin.button")}/>
            </div>
          </div>
          <div className={s.reservedBlock}>
            <div className={s.reserved}>BingoPay</div>
            <div className={s.reserved}>Copyright © 2015-2023 All rights reserved</div>
          </div>
        </div>
        <div className={s.rightSide}>
          <div className={s.textBlock}>
            <div className={s.textBlockTitle}>{t("signin.hello")}</div>
            <div className={s.textBlockDesc}>{t("signin.descOne")}<br/>
              {t("signin.descTwo")}
            </div>
            <div>
              <div className={s.back} onClick={() => {
                navigate('/')
              }}>
                {t("signin.back")}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
