import React from 'react';
import style from './Nav.module.css';
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

const Nav = (props) => {
  const {t} = useTranslation()
  return (
      <div className={style.nav}>
        <NavLink
            className={({isActive}) =>
                isActive ? `${style.active}` : `${style.link}`
            }
            to="/"
        >{t("header.home")}</NavLink>
        <NavLink
            className={({isActive}) =>
                isActive ? `${style.active}` : `${style.link}`
            }
            to={"/pricing"}
        >{t("header.pricing")}</NavLink>
        <NavLink
            className={({isActive}) =>
                isActive ? `${style.active}` : `${style.link}`
            }
            to={"/api"}
        >{t("header.api")}</NavLink>
      </div>
  );
}

export default Nav;
