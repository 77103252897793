import React, { useEffect, useState } from 'react';
import s from './CryptoWallets.module.css';
import { AuthAPI } from '../../../../networking/Api';
import WithdrawalConfirmModal from '../../../../ui/modals/WithdrawalConfirmModal/WithdrawalConfirmModal';
import { Layout } from 'antd';
import { AccountCard } from '../AccountCard/AccountCard';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../../ui/Loader/Loader';
import {
  getAccountsTransactions,
  getWithdrawalsTransactions,
} from '../../../../networking/services/accounts';
import {setIsLoading} from "../../../../store/Slices/DataReducer";


const CryptoWallets = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.auth);
  const { isLoading } = useSelector((store) => store.data);

  const [accounts, setAccounts] = useState([]);
  const [isTest, setIsTest] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [disabled, setIsDisabled] = useState(false);
  const [status, setStatus] = useState(0);
  const [cryptoWallets, setCryptoWallets] = useState([])

  const cryptoDate = cryptoWallets?.map((item)=>{
    return {
      accountNumber: item.address,
      balance: item.netAmount,
      currency: item.coin.toUpperCase()
    }
  })

/*  console.log(cryptoDate)
  function withdrawalHandler(accNumber) {
    setIsDisabled(true);
    AuthAPI.createWithdrawalToBankAccount(token, accNumber)
      .then((res) => {
        setStatus(res.status);
      })
      .catch((e) => {
        setStatus(e.status);
      })
      .finally(() => {
        setIsDisabled(false);
        setShowConfirmModal(true);
      });
  }

  useEffect(() => {
    dispatch(setIsLoading(true))
    AuthAPI.getCryptoWallets(token).then((res) => {
      console.log(res)
      setCryptoWallets(res.data.result)
    }).catch((e)=>{
      console.log(e)
      dispatch(setIsLoading(false))
    }).finally(dispatch(setIsLoading(false)));
  }, []);

  useEffect(() => {
    dispatch(getAccountsTransactions(token));
    dispatch(getWithdrawalsTransactions(token));
  }, []);*/



  return (
    <Layout>
      <WithdrawalConfirmModal
        status={status}
        showConfirmModal={showConfirmModal}
        showPopUp={setShowConfirmModal}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          Crypto Wallets
        </>
      )}
    </Layout>
  );
};

export default CryptoWallets;
