import React from 'react';
import s from './ApiGuide.module.css'
import MyCollapse from "../../ui/Collapse/MyCollapse";
import ApiHeader from "../../components/Header/ApiHeader/ApiHeader";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header/Header";

const ApiGuide = () => {
const {t} = useTranslation()
    return (
        <div>
            <Header />
            <div className={s.container}>
                <h1>{t('apiguide.title')}</h1>
                <MyCollapse/>
            </div>
        </div>
    )
}

export default ApiGuide;
