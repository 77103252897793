import {createSlice} from '@reduxjs/toolkit';
import React from "react";
import {AuthAPI} from '../../networking/Api';
import {getTransactionDetails} from "../../networking/services/accounts";
import {authMeTC} from "./AuthReducer";
import {getAcquiringTransactionData} from "./DataReducer";


const initialState = {
  isDetailsPopupShow: false,
  transactionDetails: {},
  accountTransactions: [],
  withdrawalTransactions: [],
  activeSort: {}
};


export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountTransactions: (state, action) => {
      state.accountTransactions = action.payload;
    },
    setTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
    setWithdrawalTransactions: (state, action) => {
      state.withdrawalTransactions = action.payload;
    },
    setIsDetailsPopupShow: (state, action)=>{
      state.isDetailsPopupShow = action.payload;
    },
    setActiveSort: (state, action)=>{
      state.activeSort = action.payload;
    }
  },

});

export const { setAccountTransactions, setWithdrawalTransactions, setTransactionDetails, setIsDetailsPopupShow, setActiveSort } = accountsSlice.actions;


export default accountsSlice.reducer;