export const AcquiringTransactionsSvg = ({active, width}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M15.71 15.18L12.61 13.33C12.07 13.01 11.63 12.24 11.63 11.61V7.51M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
          stroke={active === '1' ? `${width > 620 ? '#3F3F3F' : '#F6F6F6'}` : '#ABABAB'} strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const AccountsSvg = ({active, width}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M10.7516 16.8604V18.8904C10.7516 20.6104 9.15158 22.0004 7.18158 22.0004C5.21158 22.0004 3.60156 20.6104 3.60156 18.8904V16.8604M10.7516 16.8604C10.7516 18.5704 9.15158 19.8004 7.18158 19.8004M10.7516 16.8604V14.11C10.7516 13.25 10.3516 12.47 9.71161 11.91C9.06161 11.35 8.17158 11 7.18158 11C5.20158 11 3.60156 12.39 3.60156 14.11V16.8604M10.7516 16.8604C10.7516 18.5804 9.15158 19.8004 7.18158 19.8004M3.60156 16.8604C3.60156 18.5804 5.20158 19.8004 7.18158 19.8004M3.60156 16.8604C3.60156 18.5704 5.21158 19.8004 7.18158 19.8004M21 14.05C21.56 14.03 22 13.58 22 13.03V10.97C22 10.42 21.56 9.97001 21 9.95001M21 14.05H19.04C17.96 14.05 16.97 13.26 16.88 12.18C16.82 11.55 17.06 10.96 17.48 10.55C17.85 10.17 18.36 9.95001 18.92 9.95001H21M21 14.05L21 15.5C21 18.5 19 20.5 16 20.5H13.5M21 9.95001L21 8.5C21 5.76 19.33 3.84999 16.75 3.54999C16.51 3.50999 16.26 3.5 16 3.5H7C6.72 3.5 6.45 3.52 6.19 3.56C3.64 3.88 2 5.78 2 8.5V10.5M10.75 14.11C10.75 14.61 10.61 15.07 10.37 15.47C9.78 16.44 8.56998 17.05 7.16998 17.05C5.76998 17.05 4.55997 16.43 3.96997 15.47C3.72997 15.07 3.59003 14.61 3.59003 14.11C3.59003 13.25 3.99 12.48 4.63 11.92C5.28 11.35 6.16997 11.01 7.15997 11.01C8.14997 11.01 9.04 11.36 9.69 11.92C10.35 12.47 10.75 13.25 10.75 14.11Z"
          stroke={active === '2' ? `${width > 620 ? '#3F3F3F' : '#F6F6F6'}` : '#ABABAB'} strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export const RollingReserveSvg = ({active, width}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M5 9.5H7.48C8.86 9.5 9.98 10.62 9.98 12C9.98 13.38 8.86 14.5 7.48 14.5H5M19 9.98999H16M19 14H16M7.2002 12H7.3002M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22ZM8 19H16C18 19 19 18 19 16V8C19 6 18 5 16 5H8C6 5 5 6 5 8V16C5 18 6 19 8 19Z"
          stroke={active === '3' ? `${width > 620 ? '#3F3F3F' : '#F6F6F6'}` : '#ABABAB'} strokeWidth="2"
          strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
    </svg>
)

export const ApiSettingsSvg = ({active, width}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M9.5 13.9998L7.5 15.9998L9.5 17.9998M14.5 13.9998L16.5 15.9998L14.5 17.9998M19.54 6.53973L16.47 3.46973C15.53 2.52973 14.26 2.00977 12.93 2.00977H8C5 2.00977 3 4.00977 3 7.00977V17.0098C3 20.0098 5 22.0098 8 22.0098H16C19 22.0098 21 20.0098 21 17.0098V10.0798C21 8.73977 20.47 7.46973 19.54 6.53973Z"
          stroke={active === '4' ? `${width > 620 ? '#3F3F3F' : '#F6F6F6'}` : '#ABABAB'} strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export const MobileMenuSvg = ({active}) => (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H19M1 7H19M1 13H19" stroke="#3F3F3F" strokeWidth="2" strokeLinecap="round"/>
    </svg>
)

export const MobileMenuCloseSvg = ({active}) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M9 10.75L2.875 16.875C2.64583 17.1042 2.35417 17.2188 2 17.2188C1.64583 17.2188 1.35417 17.1042 1.125 16.875C0.895833 16.6458 0.78125 16.3542 0.78125 16C0.78125 15.6458 0.895833 15.3542 1.125 15.125L7.25 9L1.125 2.875C0.895833 2.64583 0.78125 2.35417 0.78125 2C0.78125 1.64583 0.895833 1.35417 1.125 1.125C1.35417 0.895833 1.64583 0.78125 2 0.78125C2.35417 0.78125 2.64583 0.895833 2.875 1.125L9 7.25L15.125 1.125C15.3542 0.895833 15.6458 0.78125 16 0.78125C16.3542 0.78125 16.6458 0.895833 16.875 1.125C17.1042 1.35417 17.2188 1.64583 17.2188 2C17.2188 2.35417 17.1042 2.64583 16.875 2.875L10.75 9L16.875 15.125C17.1042 15.3542 17.2188 15.6458 17.2188 16C17.2188 16.3542 17.1042 16.6458 16.875 16.875C16.6458 17.1042 16.3542 17.2188 16 17.2188C15.6458 17.2188 15.3542 17.1042 15.125 16.875L9 10.75Z"
          fill="#ABABAB"/>
    </svg>
)


export const UserIconSvg = ({size}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_33_244)">
        <path
            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7.013 18.256C8.42855 19.3877 10.1877 20.0029 12 20C13.97 20 15.773 19.288 17.167 18.108C16.5157 17.4397 15.7371 16.9089 14.877 16.5468C14.017 16.1847 13.0931 15.9988 12.16 16C11.1926 15.9989 10.2355 16.1989 9.34947 16.5872C8.46343 16.9756 7.66778 17.5439 7.013 18.256ZM5.616 16.82C6.45645 15.9281 7.47067 15.2177 8.59614 14.7327C9.72161 14.2477 10.9345 13.9984 12.16 14C13.3417 13.9985 14.512 14.2304 15.6037 14.6824C16.6955 15.1344 17.6873 15.7976 18.522 16.634C19.3781 15.4291 19.8836 14.0106 19.9826 12.5359C20.0815 11.0612 19.77 9.58789 19.0825 8.27946C18.395 6.97102 17.3585 5.87862 16.088 5.12345C14.8174 4.36827 13.3625 3.97984 11.8846 4.00125C10.4067 4.02267 8.96366 4.45308 7.71552 5.24476C6.46738 6.03643 5.46296 7.1584 4.81369 8.4862C4.16442 9.814 3.89569 11.2957 4.03732 12.7669C4.17894 14.2382 4.72537 15.6414 5.616 16.821V16.82ZM12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9C8 7.93913 8.42143 6.92172 9.17157 6.17157C9.92172 5.42143 10.9391 5 12 5C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13ZM12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7C11.4696 7 10.9609 7.21071 10.5858 7.58579C10.2107 7.96086 10 8.46957 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11Z"
            fill="#3F3F3F"/>
      </g>
      <defs>
        <clipPath id="clip0_33_244">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
)


export const MenuFilterSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 8H19" stroke="#3F3F3F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 12H16" stroke="#3F3F3F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 16H14" stroke="#3F3F3F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)



export const CopySvg = ({active, activeColor, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M21.1762 1.82376C20.6488 1.29632 19.9334 1 19.1875 1H9.8125C9.06658 1 8.35121 1.29632 7.82376 1.82376C7.29632 2.35121 7 3.06658 7 3.8125V4.5H8.875V3.8125C8.875 3.56386 8.97377 3.3254 9.14959 3.14959C9.3254 2.97377 9.56386 2.875 9.8125 2.875H19.1875C19.4361 2.875 19.6746 2.97377 19.8504 3.14959C20.0262 3.3254 20.125 3.56386 20.125 3.8125V16.9375C20.125 17.1861 20.0262 17.4246 19.8504 17.6004C19.6746 17.7762 19.4361 17.875 19.1875 17.875H18V19.75H19.1875C19.9334 19.75 20.6488 19.4537 21.1762 18.9262C21.7037 18.3988 22 17.6834 22 16.9375V3.8125C22 3.06658 21.7037 2.35121 21.1762 1.82376Z" fill="#3F3F3F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1762 5.82376C15.6488 5.29632 14.9334 5 14.1875 5H4.8125C4.06658 5 3.35121 5.29632 2.82376 5.82376C2.29632 6.35121 2 7.06658 2 7.8125V20.9375C2 21.6834 2.29632 22.3988 2.82376 22.9262C3.35121 23.4537 4.06658 23.75 4.8125 23.75H14.1875C14.9334 23.75 15.6488 23.4537 16.1762 22.9262C16.7037 22.3988 17 21.6834 17 20.9375V7.8125C17 7.06658 16.7037 6.35121 16.1762 5.82376ZM4.8125 6.875H14.1875C14.4361 6.875 14.6746 6.97377 14.8504 7.14959C15.0262 7.3254 15.125 7.56386 15.125 7.8125V20.9375C15.125 21.1861 15.0262 21.4246 14.8504 21.6004C14.6746 21.7762 14.4361 21.875 14.1875 21.875H4.8125C4.56386 21.875 4.3254 21.7762 4.14959 21.6004C3.97377 21.4246 3.875 21.1861 3.875 20.9375V7.8125C3.875 7.56386 3.97377 7.3254 4.14959 7.14959C4.3254 6.97377 4.56386 6.875 4.8125 6.875Z" fill={active ? activeColor : "#3F3F3F"}/>
    </svg>
)

export const CloseSvg = ({...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path d="M15 16.75L8.875 22.875C8.64583 23.1042 8.35417 23.2188 8 23.2188C7.64583 23.2188 7.35417 23.1042 7.125 22.875C6.89583 22.6458 6.78125 22.3542 6.78125 22C6.78125 21.6458 6.89583 21.3542 7.125 21.125L13.25 15L7.125 8.875C6.89583 8.64583 6.78125 8.35417 6.78125 8C6.78125 7.64583 6.89583 7.35417 7.125 7.125C7.35417 6.89583 7.64583 6.78125 8 6.78125C8.35417 6.78125 8.64583 6.89583 8.875 7.125L15 13.25L21.125 7.125C21.3542 6.89583 21.6458 6.78125 22 6.78125C22.3542 6.78125 22.6458 6.89583 22.875 7.125C23.1042 7.35417 23.2188 7.64583 23.2188 8C23.2188 8.35417 23.1042 8.64583 22.875 8.875L16.75 15L22.875 21.125C23.1042 21.3542 23.2188 21.6458 23.2188 22C23.2188 22.3542 23.1042 22.6458 22.875 22.875C22.6458 23.1042 22.3542 23.2188 22 23.2188C21.6458 23.2188 21.3542 23.1042 21.125 22.875L15 16.75Z" fill="#797979"/>
    </svg>
)
