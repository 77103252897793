import React, {useEffect, useState} from 'react';
import s from './AccountTransactionsTable.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ConfigProvider, Pagination, Table} from 'antd';
import i18next from 'i18next';
import {getTransactionDetails} from '../../../../../networking/services/accounts';
import {setActiveSort} from "../../../../../store/Slices/AccountsReducer";

const AccountTransactionsTable = (props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const {token} = useSelector((store) => store.auth);
  const [currentData, setCurrentData] = useState([]);

  const tableHeader = {
    transactionNumber: `${t('personal.transactionHistory.transactionNumber')}`,
    date: `${t('personal.transactionHistory.date')}`,
    amount: `${t('personal.transactionHistory.amount')}`,
    status: props.isTransactions
        ? `${t('personal.transactionHistory.type')}`
        : `${t('personal.transactionHistory.status')}`,
    action: '',
  };

  const transactionsStatus = (status) => {
    switch (status) {
      case 'transaction.status.in_process':
        return `${t('personal.transactionHistory.inProcess')}`;
        break;
      case 'transaction.status.approved':
        return `${t('personal.transactionHistory.approved')}`;
        break;
      case 'transaction.status.denied':
        return `${t('personal.transactionHistory.denied')}`;
        break;
      case 'transaction.status.send_to_bank':
        return `${t('personal.transactionHistory.sendToBank')}`;
        break;
      case 'transaction.status.error':
        return `${t('personal.transactionHistory.error')}`;
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: `${i18next.t('personal.transactionHistory.transactionNumber')}`,
      dataIndex: 'id',
    },
    {
      title: `${i18next.t('personal.transactionHistory.date')}`,
      dataIndex: 'date',
      sorter: true,
    },
    {
      title: `${i18next.t('personal.transactionHistory.amount')}`,
      dataIndex: 'amount',
      sorter: true,
    },
    {
      title: props.isTransactions
          ? `${t('personal.transactionHistory.type')}`
          : `${t('personal.transactionHistory.status')}`,
      dataIndex: 'status',
    },
    {
      title: '',
      dataIndex: 'action',
    },
  ];

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const paginatedData = props.tableData?.slice(startIndex, endIndex);
    setCurrentData(paginatedData);
  }, [currentPage, props.tableData]);

  const onChange = (pagination, filters, sorter) => {
    const {order, field} = sorter;
    if (order === undefined) {
      dispatch(setActiveSort({}))
    } else {
      dispatch(setActiveSort({order, field}))
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const customItemRender = (current, type, originalElement) => {
    if (type === 'page' && current === currentPage) {
      return (
          <p
              style={{
                backgroundColor: 'transparent',
                color: '#000',
                fontSize: 18,
                fontWeight: 500,
                fontFamily: 'Cera Pro',
              }}
          >
            {current}
          </p>
      );
    }
    return originalElement;
  };

  const customPagination = {
    className: `${s.custom_pagination}`,
    current: currentPage,
    onChange: handlePageChange,
    itemRender: customItemRender,
  };

  const detailsHandler = (transactionNumber) => {
    dispatch(getTransactionDetails({transactionNumber, token}));
  };

  return (
      <div>
        <img
            className={s.blankHeader}
            src={require('../../../../../assets/img/blankHeader.png')}
            alt=''
        />

        <div className={s.transactionHistoryDesktop}>
          <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#DF853B',
                },
              }}
          >
            <Table
                rowClassName={s.tableRow}
                style={{backgroundColor: 'transparent'}}
                columns={columns}
                dataSource={props.tableData?.map((item) => {
                  return {
                    key: item.transactionNumber,
                    id: item.transactionNumber,
                    date: item.date,
                    amount: `${item.amount} ${item.currency}`,
                    status: props.isTransactions
                        ? item.type
                        : `${transactionsStatus(item.status)}`,
                    action: props.isTransactions ? (
                        <div
                            onClick={() => detailsHandler(item.transactionNumber)}
                            className={s.img}
                        >
                          <img
                              src={require('../../../../../assets/img/details.png')}
                              alt='details_img'
                          />
                        </div>
                    ) : null,
                  };
                })}
                onChange={onChange}
                pagination={customPagination}
            />
          </ConfigProvider>
        </div>

        <div className={s.transactionHistoryMobile}>
          <div className={s.transactionsList}>
            {currentData?.map((transaction) => {
              return (
                  <div
                      className={s.transactionBlock}
                      key={transaction.transactionNumber}
                  >
                    <div className={s.transactionRow}>
                      <div className={s.rowBlock}>
                        <div className={s.valueName}>
                          {tableHeader.transactionNumber}
                        </div>
                        <div className={s.valuePayload}>
                          {transaction.transactionNumber}
                        </div>
                      </div>
                    </div>
                    <div className={s.transactionRow}>
                      <div className={s.rowBlock}>
                        <div className={s.valueName}>{tableHeader.date}</div>
                        <div className={s.valuePayload}>{transaction.date}</div>
                      </div>
                    </div>
                    <div className={s.transactionRow}>
                      <div className={s.rowBlock}>
                        <div className={s.valueName}>{tableHeader.amount}</div>
                        <div className={s.valuePayload}>
                          {transaction.amount} {transaction.currency}
                        </div>
                      </div>
                    </div>

                    <div className={s.transactionRow}>
                      <div className={s.rowBlock}>
                        <div className={s.valueName}>{tableHeader.status}</div>
                        <div className={s.valuePayload}>
                          {!props.isTransactions
                              ? transactionsStatus(transaction.status)
                              : transaction.type}
                        </div>
                      </div>
                    </div>

                    {props.isTransactions && (
                        <div className={s.transactionRow}>
                          <div
                              className={s.detailsMobile}
                              onClick={() =>
                                  detailsHandler(transaction.transactionNumber)
                              }
                          >
                            <img
                                width={46}
                                height={35}
                                style={{marginRight: '16px'}}
                                src={require('../../../../../assets/img/details.png')}
                                alt='details_img'
                            />

                            <div className={s.valueName}>
                              {t('personal.accountList.details')}
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
              );
            })}
          </div>
          <Pagination
              pageSize={10}
              total={props.tableData?.length}
              {...customPagination}
          />
        </div>
      </div>
  );
};

export default AccountTransactionsTable;
