import React from 'react';
import s from './MyCollapse.module.css'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Accordion} from "@material-ui/core";
import {
  loginRequest, loginResponse,
  refundRequestExample,
  refundResponse,
  refundResponseError,
  refundResponseError403,
  requestPurchasesError,
  requestPurchasesError500,
  responsePurchases, toolSignatureRequestExample,
  transactionInfoResponse,
  transactionInfoResponseError, transactionsListRequestExample,
  transactionsListResponse, transactionsListResponseError,
  TransactionStatusRequest
} from "../../components/ExampleCodeDataForApi/CodeDataForApi";
import {useTranslation} from "react-i18next";


const MyCollapse = () => {
  const {t} = useTranslation();
  const codeExample = 'headers: Content-Type: application/json,'

  return (
      <div>
        <h2>{t("apiguide.apiTitle")}</h2>
        <h3>{t("apiguide.apiSubtitle")}</h3>
        <p>{t("apiguide.example")}</p>
        <div className={s.mb}>
                <pre>
                    <code>
                        <div className={s.code_block} dangerouslySetInnerHTML={{__html: codeExample}}></div>
                    </code>
                </pre>
        </div>
        <h2>Log In:</h2>
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>POST <code>https://api1.dataprotect.site/api/user/login</code>

            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Parameters</b>
            </div>
            <div className={s.content}>
              <div className={s.responses_block}>
                <div className={s.w100}>
                  <div className={`${s.name} ${s.params}`}>
                    <span className={s.params_name}><b>Name</b></span>
                    <span className={s.params_desc}><b>Description</b></span>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>login</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        your login
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>password </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        your password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.subtitle}>
              <b>Request Body</b><span className={s.required}>required</span>
            </div>
            <div>
                        <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: loginRequest}}></div>
                            </div>
                        </pre>
            </div>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                                <div className={s.code_block}>
                                    <div dangerouslySetInnerHTML={{__html: loginResponse}}></div>
                                </div>
                            </pre>
                <div style={{fontWeight: "bold", fontSize: "18px"}}>
                  after successful authorization and receiving a token, send this token in the headers with each
                  request <br/>
                  Example : {`" headers : { Content-Type: application/json, Authorization: Bearer TOKEN } "`}
                </div>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  500
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError500}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h2>Tool Signature Request:</h2>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>POST <code>https://api1.dataprotect.site/api/transaction/creates/payments</code>
            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Parameters</b>
            </div>
            <div className={s.content}>
              <div className={s.responses_block}>
                <div className={s.w100}>
                  <div className={`${s.name} ${s.params}`}>
                    <span className={s.params_name}><b>Name</b></span>
                    <span className={s.params_desc}><b>Description</b></span>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>payer_id</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        your id in our system (check with the admin)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>owner </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        Payer's name (From the card)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>card_number </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>Card number (From the card)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>cvv </b>
                      <div>integer</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>number on the back of the card (From the card)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>validity </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>card expiry date. Example: “01/22” (From card)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>amount </b>
                      <div>float/integer</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>write-off amount
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>currency </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>currency . Example: "USD", always in upper case.
                      </div>
                    </div>
                  </div>
                  {/*<div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>callback_url </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>"https://www.example.com/"
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className={s.subtitle}>
              <b>Request Body</b><span className={s.required}>required</span>
            </div>
            <div>
                        <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: toolSignatureRequestExample}}></div>
                            </div>
                        </pre>
            </div>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                                <div className={s.code_block}>
                                    <div dangerouslySetInnerHTML={{__html: responsePurchases}}></div>
                                </div>
                            </pre>
                <div>
               {/*   After successfully completing the 3DS, you will be redirected to the page that you
                  specified in the callback_url parameter.*/}
                  You will also be given 2 parameters - transactionNumber ,
                  transactionStatus.
                  If you decide to pass your parameters to the url, we will add them. And add the number
                  and status of the transaction at the end. <br/>
                 {/* Example: "exemple.com/?you_parametr=exemple&transactionNumber=1234&transactionStatus=2"*/}
                </div>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  500
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError500}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h2>Transaction status request:</h2>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>GET <code>https://api1.dataprotect.site/api/transaction/transactions/{`{id}`}/status</code>
            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Parameters</b>
            </div>
            <div className={s.content}>
              <div className={s.responses_block}>
                <div className={s.w100}>
                  <div className={`${s.name} ${s.params}`}>
                    <span className={s.params_name}><b>Name</b></span>
                    <span className={s.params_desc}><b>Description</b></span>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>id</b>
                      <div>string</div>
                      <div>($query)</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        transaction number (you receive in the response with a successful request to
                        withdraw funds)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: TransactionStatusRequest}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  500
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError500}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h2>Refund:</h2>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>POST <code> https://api1.dataprotect.site/api/transaction/refunds</code>
            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Parameters</b>
            </div>
            <div className={s.content}>
              <div className={s.responses_block}>
                <div className={s.w100}>
                  <div className={`${s.name} ${s.params}`}>
                    <span className={s.params_name}><b>Name</b></span>
                    <span className={s.params_desc}><b>Description</b></span>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>payer_id</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        your id in our system (check with the admin)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>transaction_id </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        transaction number
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.subtitle}>
              <b>Request Body</b><span className={s.required}>required</span>
            </div>
            <div>
                        <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: refundRequestExample}}></div>
                            </div>
                        </pre>
            </div>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: refundResponse}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: refundResponseError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  403
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: refundResponseError403}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  500
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: requestPurchasesError500}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h2>Transaction info:</h2>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>GET <code>https://api1.dataprotect.site/api/transaction/v1/transactions/{`{transaction_number}`}/info</code>
            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: transactionInfoResponse}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: transactionInfoResponseError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h2>Transactions list:</h2>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h5 className={s.endpoint_title}>POST <code>https://api1.dataprotect.site/api/transaction/all/transactions/info</code>
            </h5>
          </AccordionSummary>
          <AccordionDetails className={s.flex}>
            <div className={s.subtitle}>
              <b>Parameters</b>
            </div>
            <div className={s.content}>
              <div className={s.responses_block}>
                <div className={s.w100}>
                  <div className={`${s.name} ${s.params}`}>
                    <span className={s.params_name}><b>Name</b></span>
                    <span className={s.params_desc}><b>Description</b></span>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>payer_id</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        your id in our system (check with the admin)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>isLiveTransaction </b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>
                        optional (flag test transaction or not)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>startDate</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>optional (passing date in timestamp format)
                      </div>
                    </div>
                  </div>
                  <div className={`${s.name} ${s.params}`}>
                    <div className={s.params_name}>
                      <b>endDate</b>
                      <div>string</div>
                    </div>
                    <div className={s.params_desc}>
                      <div>optional (passing date in timestamp format)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.subtitle}>
              <b>Request Body</b><span className={s.required}>required</span>
            </div>
            <div>
                        <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: transactionsListRequestExample}}></div>
                            </div>
                        </pre>
            </div>
            <div className={s.subtitle}>
              <b>Responses</b>
            </div>
            <div>Response status is always HTTP_OK (200)</div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div className={s.name}>
                  <b>Code</b>
                </div>
                <div>
                  200
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.name}><b>Description</b></div>
                <div>OK</div>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: transactionsListResponse}}></div>
                            </div>
                        </pre>
              </div>
            </div>
            <div className={s.responses_block}>
              <div className={s.status_code_block}>
                <div>
                  418
                </div>
              </div>
              <div className={s.responses_desc_block}>
                <div className={s.example}>Example value</div>
                <pre>
                            <div className={s.code_block}>
                                <div dangerouslySetInnerHTML={{__html: transactionsListResponseError}}></div>
                            </div>
                        </pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
  )
}


export default MyCollapse
